<template>
  <div :style="{ marginTop: isWxApplets ? '46px' : '' }">
    <!-- <div style="height: 25px; background: #fff"></div>
    <div class="head">
      优惠券
      <img
        class="left"
        @click="$router.back(-1)"
        src="../../../assets/ShopImg/left.png"
        alt=""
      />
    </div> -->
    <template v-if="isWxApplets">
      <van-nav-bar
        title="我的优惠券"
        fixed
        left-arrow
        @click-left="onClickLeft"
      />
    </template>
    <van-tabs
      v-model="active"
      color="#000"
      title-active-color="#000"
      title-inactive-color="#666666"
      @click="changeTabStatus"
    >
      <van-tab title="待使用">
        <div name="0" class="outside" v-if="couponList.length > 0">
          <!-- 不能使用 class="yh_content2"  -->
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="postQueryMyCouponList"
            v-if="couponList.length > 0"
          >
            <div class="yh_content" v-for="(item, i) in couponList" :key="i">
              <div class="yh_content_money">
                <p>
                  <span style="fontsize: 11px">￥</span>{{ item.DiscountMoney }}
                </p>
                <p>满{{ item.FullMoney }}元可用</p>
              </div>
              <div class="yh_content_detail">
                <p>{{ item.Title }}</p>
                <p>有效期至{{ item.EndTime }}</p>
              </div>
              <!-- <div class="btns">立即领取</div> -->
              <!-- <img
              class="discount_type"
              src="../../../assets/ShopImg/ygq.png"
              alt=""
            /> -->
              <!-- <img class="discount_type"  src="../../../assets/ShopImg/ysy.png" alt=""> -->
            </div>
          </van-list>
        </div>
        <div v-else>
          <nocontent name="暂无优惠券"></nocontent>
        </div>
      </van-tab>
      <van-tab name="1" title="已使用">
        <div name="0" class="outside" v-if="couponList.length > 0">
          <!-- 不能使用 class="yh_content2"  -->
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="postQueryMyCouponList"
            v-if="couponList.length > 0"
          >
            <div class="yh_content" v-for="(item, i) in couponList" :key="i">
              <div class="yh_content_money">
                <p>
                  <span style="fontsize: 11px">￥</span>{{ item.DiscountMoney }}
                </p>
                <p>满{{ item.FullMoney }}元可用</p>
              </div>
              <div class="yh_content_detail">
                <p>{{ item.Title }}</p>
                <p>有效期至{{ item.EndTime }}</p>
              </div>
              <!-- <div class="btns">立即领取</div> -->
              <img
                class="discount_type"
                src="../../../assets/ShopImg/ysy.png"
                alt=""
              />
              <!-- <img class="discount_type"  src="../../../assets/ShopImg/ysy.png" alt=""> -->
            </div>
          </van-list>
        </div>
        <div v-else>
          <nocontent name="暂无优惠券"></nocontent>
        </div>
      </van-tab>
      <van-tab title="已过期">
        <div name="0" class="outside" v-if="couponList.length > 0">
          <!-- 不能使用 class="yh_content2"  -->
          <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="postQueryMyCouponList"
            v-if="couponList.length > 0"
          >
            <div class="yh_content" v-for="(item, i) in couponList" :key="i">
              <div class="yh_content_money">
                <p>
                  <span style="fontsize: 11px">￥</span>{{ item.DiscountMoney }}
                </p>
                <p>满{{ item.FullMoney }}元可用</p>
              </div>
              <div class="yh_content_detail">
                <p>{{ item.Title }}</p>
                <p>有效期至{{ item.EndTime }}</p>
              </div>
              <!-- <div class="btns">立即领取</div> -->
              <img
                class="discount_type"
                src="../../../assets/ShopImg/ygq.png"
                alt=""
              />
              <!-- <img class="discount_type"  src="../../../assets/ShopImg/ysy.png" alt=""> -->
            </div>
          </van-list>
        </div>
        <div v-else>
          <nocontent name="暂无优惠券"></nocontent>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import { queryMyCouponList } from "@/api/shop";
import nocontent from "@/views/shop/components/nocontent";
export default {
  components: {
    nocontent,
  },
  data() {
    return {
      couponList: [],
      usecouponList: [],
      outTimeCouponList: [],
      userInfo: {},
      active: 0,
      pageIndex: 1,
      pageSize: 10,
      loading: false,
      finished: false,
      status: 0,
      isEnd: false,
      isWxApplets: true,
    };
  },
  created() {
    //判断是否是微信浏览器的函数
    var ua = window.navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
      document.title = "我的优惠券";
      this.isWxApplets = false;
    }
    // window.addEventListener('scroll', this.listenScroll);
  },
  mounted() {
    if (localStorage.getItem("userInfo") != null) {
      this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
      this.postQueryMyCouponList();
    } else {
      this.userInfo.Id = 0;
    }
  },
  methods: {
    // 顶部返回
    onClickLeft() {
      window.history.go(-1);
    },
    changeTabStatus(name, title) {
      this.status = name;
      this.pageIndex = 1;
      this.couponList = [];
      this.postQueryMyCouponList();
    },
    async postQueryMyCouponList() {
      let params = {};
      params.UserId = this.userInfo.Id;
      params.pageIndex = this.pageIndex;
      params.pageSize = this.pageSize;
      params.Status = this.status;
      let res = await queryMyCouponList(params);
      this.couponList = this.couponList.concat(res.response.data);
      this.pageIndex++;
      // 加载状态结束
      this.loading = false;
      // 数据全部加载完成
      if (this.couponList.length >= res.response.dataCount) {
        this.finished = true;
      }
    },
    //滚动
    listenScroll() {
      let that = this;
      let ele = document.documentElement;
      let scr = ele.scrollTop; // 向上滚动的那一部分高度
      let clientHeight = ele.clientHeight; // 屏幕高度也就是当前设备静态下你所看到的视觉高度
      let scrHeight = ele.scrollHeight; // 整个网页的实际高度，兼容Pc端
      if (scr + clientHeight + 5 >= scrHeight) {
        // console.log(that.isEnd);
        // if (that.isLoad == false||this.isEnd == false) {
        if (that.isEnd == false) {
          // that.isLoad = true;
          that.pageIndex++;
          this.postQueryMyCouponList();
        }
      }
    },
  },
  beforeDestroy() {
    // window.removeEventListener('scroll', this.listenScroll);
  },
};
</script>

<style lang="scss" scoped>
.head {
  height: 50px;
  line-height: 50px;
  width: 100%;
  text-align: center;
  color: #333;
  font-size: 16px;
  font-weight: bold;
  position: relative;
  background: #fff;

  .left {
    width: 22px;
    height: 17px;
    position: absolute;
    left: 4%;
    top: 50%;
    transform: translateY(-50%);
  }
}
.yh_content {
  width: 100%;
  height: 100px;
  background-image: url("../../../assets/ShopImg/yh.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  box-shadow: 5px 4px 6px rgba(146, 146, 146, 0.16);
  position: relative;
  display: flex;
  margin-bottom: 15px;
}

.yh_content2 {
  width: 100%;
  height: 100px;
  background-image: url("../../../assets/ShopImg/yh2.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  box-shadow: 5px 4px 6px rgba(146, 146, 146, 0.16);
  position: relative;
  display: flex;
  margin-bottom: 15px;
}

.yh_content_money {
  width: 32.5%;
  text-align: center;
  margin-top: 5px;

  p {
    color: #fff;
  }
}

.yh_content_money p:first-child {
  font-size: 22px;
}

.yh_content_money p:last-child {
  font-size: 10px;
}

.yh_content_detail {
  margin-left: 15px;
  margin-top: 15px;
}

.yh_content_detail p:first-child {
  color: #333333;
  font-size: 14px;

  font-weight: bold;
}

.yh_content_detail p:last-child {
  font-size: 8px;
  color: #666666;
  margin-top: 12px;
}

.btns {
  width: 60px;
  height: 24px;
  background: #fff;
  position: absolute;
  top: 70%;
  left: 7%;
  border-radius: 20px;
  font-size: 8px;
  color: #f95e2e;
  line-height: 24px;
  text-align: center;
}

.outside {
  padding: 15px 15px 15px 15px;
}
.discount_type {
  width: 52px;
  height: 40px;
  position: absolute;
  right: 3%;
  top: 39%;
}
/deep/ .van-nav-bar .van-icon {
  color: #000;
  font-size: 20px;
}
</style>
